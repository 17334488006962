const prod = {
  url: {
   API_URL: `https://4np4zlafng.execute-api.us-west-1.amazonaws.com/`
  }
 }
 const dev = {
  url: {
   API_URL: 'http://localhost:3001/'
  }
 }
 export const config = process.env.NODE_ENV === 'development' ? dev : prod