import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import NavBar from '../Components/navbar';

import Main from './Main/main'
import Building from './Buildings/buildings';
import ShowBuilding from './Buildings/showBuilding';
import Settings from './Settings/settings';
import Events from './Events/events'
import Users from './Users/users';
import Organizations from './Organizations/organizations'
import Footer from '../Components/footer'

function Admin({ userObj, }) {

  return (
    <Router>
      <NavBar userObj={userObj}/>
      <Switch>
        <Route path='/buildings'>
          <Building userObj={userObj} />
        </Route>
        <Route path='/building/:id'>
          <ShowBuilding userObj={userObj}/>
        </Route>
        <Route path='/users'>
          <Users userObj={userObj}/>
        </Route>
        <Route path='/events'>
          <Events userObj={userObj}/>
        </Route>
        <Route path='/settings'>
          <Settings />
        </Route>
        <Route path='/orgs'>
          <Organizations userObj={userObj}/>
        </Route>
        <Route path='/'>
          <Main userObj={userObj}/>
        </Route>
      </Switch>
      <Footer />
    </Router>
  )
}

export default Admin;