import React from 'react';
import SingleEvent from '../Events/singleEvent';
import { getActiveIncidents } from '../../api/api';

function Main({ userObj }) {
  const [currentEvents, setCurrentEvents] = React.useState();

  React.useEffect(() => {
    getActiveIncidents(setCurrentEvents, userObj)
  }, [userObj])

  return(
    <div>
      {
        currentEvents?.length > 0 ? 
        <>
          <h1>Current Incidents</h1>
          {
            currentEvents.map(event =>
              <SingleEvent key={event.ID} myEvent={event}/>
            )
          }
        </>
        : null
      }
    </div>
  )
}

export default Main;