import React from 'react';
import MapCanvas from '../../../Components/Canvas/MapCanvas';
import DisplayRooms from './displayRooms';
import { getBuildingImageByBuildingID, postRoomByBuildingID } from '../../../api/api';
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
`

const Row = styled.div`
    display: flex;
    flex-direction: column;
    align-content: center;
    max-height: ${props => props.imageHeight || '300px'};
    overflow: auto;
`

const Button = styled.button`
  border-radius: 5px;
  border: 2px solid #ec2c38;
  background-color: white;
  margin-bottom: 10px;
  padding: 5px;
  width: 100px;
  height: 35px;
`


function AddRooms({ buildingID, existingRooms, setExistingRooms, deleteExistingRoom, setAddRooms, nextStep, displayNewRooms }) {

    const [myBuilding, setMyBuilding] = React.useState();
    const [ addRoomsList, setAddRoomsList ] = React.useState([]);
    const [ imageHeight, setImageHeight ] = React.useState()
    const history = useHistory();

    function redirectToBuilding(id) {
        history.replace(`/building/${id}`)
    }

    React.useEffect(() => {
        getBuildingImageByBuildingID(setMyBuilding, buildingID, setImageHeight)
    }, [buildingID]);

    function deleteRoom(ID) {
        let tempList = [];
        addRoomsList.forEach(room => {
            if(room.ID !== ID){
                tempList.push(room)
            }
        })
        setAddRoomsList([...tempList]);
    }

    function updateRoom(roomUpdate) {
        let tempList = [];
        addRoomsList.forEach(room => {
            if(room.ID === roomUpdate.ID){
                tempList.push(roomUpdate)
            } else {
                tempList.push(room)
            }
        })
        setAddRoomsList([...tempList])
    }

    function handleSubmit(e) {
        e.preventDefault();
        postRoomByBuildingID(addRoomsList, buildingID, redirectToBuilding)
        if(existingRooms) {
            setAddRooms(false)
            displayNewRooms(addRoomsList)
        } 
    }

    return (
        <Container>
            {
                myBuilding ?
                <MapCanvas mapImage={myBuilding} clickable={true} addRoomsList={addRoomsList} setAddRoomsList={setAddRoomsList} existingRooms={existingRooms}/>
                : <p>Loading....</p>
            }
            <Row imageHeight={imageHeight}> 
                <h3>Add Rooms</h3>
                <DisplayRooms addRoomsList={addRoomsList} deleteRoom={deleteRoom} updateRoom={updateRoom} adding={true} existingRooms={existingRooms} deleteExistingRoom={deleteExistingRoom} setExistingRooms={setExistingRooms}/>
                <Button onClick={handleSubmit}>Submit</Button>
            </Row>
        </Container>
    )
}

export default AddRooms;