import React from 'react';
import { getBuildingsByOrgID, getRoomsByBuildingID, submitNewUser } from '../../api/api'
import StatefulSelect from '../../Components/Common/StatefulSelect'
import styled from 'styled-components'

const Table = styled.ul`
    display: flex;
    flex-direction: row;
    align-content: center;
    margin-left: 15%;
`

const TableRow = styled.li`
    display: flex;
    flex-direction: column;
    margin: 2px;
`

const TableRowLast = styled.li`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
`

const Label = styled.p`
    font-size: 1em;
    font-weight: bold;
    border-bottom: 1px solid #ccc!important;
`

const Button = styled.button`
  border-radius: 5px;
  border: 2px solid #ec2c38;
  background-color: white;
  margin-top: 12px;
  padding: 5px;
  width: 100px;
`

function AddUser({ userObj, showAddedUser }) {

    const [ buildings, setBuildings ] = React.useState()
    const [ myBuilding, setMyBuilding ] = React.useState()
    const [ rooms, setRooms ] = React.useState()
    const [ myRoom, setMyRoom ] = React.useState()
    const [ isAdmin, setIsAdmin ] = React.useState()
    const [ role, setRole ] = React.useState()
    const [ email, setEmail ] = React.useState()
    const [ nameFirst, setNameFirst ] = React.useState()
    const [ nameLast, setNameLast ] = React.useState()

    React.useEffect(() => {
        getBuildingsByOrgID(setBuildings, userObj)
    }, [userObj])

    React.useEffect(() => {
        getRoomsByBuildingID(myBuilding, setRooms)
    }, [myBuilding])

    function handleBuildingSelect(e) {
        setMyBuilding(e.target.value)
    }

    function handleRoomSelect(e) {
        setMyRoom(e.target.value)
    }

    function handleTextInput(e, setter) {
        setter(e.target.value)
    }

    function handleSubmit() {
        let awsData = {
            'username': email,
            'password': 'password123',
            'attributes': {
                'email': email,
                'given_name': nameFirst,
                'family_name': nameLast,
            }
        }
        let pgData = {
            orgID: userObj.Organization_ID,
            roomID: myRoom,
            bldgID: myBuilding,
            nameFirst,
            nameLast,
            admin: isAdmin,
            role
        }
        submitNewUser(awsData, pgData, showAddedUser)
    }

    return (
        <Table>
            <TableRow><Label>Building</Label><StatefulSelect myList={buildings} setter={handleBuildingSelect}/></TableRow>
            <TableRow><Label>Room</Label><StatefulSelect myList={rooms} setter={handleRoomSelect}/></TableRow>
            <TableRow><Label>Admin</Label><input type='checkbox' value={isAdmin} onChange={() => setIsAdmin(!isAdmin)}/></TableRow>
            <TableRow><Label>Role</Label><input value={role} onChange={e => handleTextInput(e, setRole)}/></TableRow>
            <TableRow><Label>Email</Label><input type='email' value={email} onChange={e => handleTextInput(e, setEmail)}/></TableRow>
            <TableRow><Label>First Name</Label><input value={nameFirst} onChange={e => handleTextInput(e, setNameFirst)}/></TableRow>
            <TableRow><Label>Last Name</Label><input value={nameLast} onChange={e => handleTextInput(e, setNameLast)}/></TableRow>
            <TableRowLast><Button onClick={handleSubmit}>Submit</Button></TableRowLast>
        </Table>
    )
}

export default AddUser;