import './App.css';
import Amplify from 'aws-amplify';
import { withAuthenticator } from '@aws-amplify/ui-react';
import awsconfig from './aws-exports';
import Admin from './Screens/admin';
import NotAdmin from './Screens/notAdmin';
import UserValidator from './Components/userValidator';

Amplify.configure(awsconfig);

function App() {
  
  return (
    <div className="App">
      <UserValidator Valid={Admin} NotValid={NotAdmin} />
    </div>
  );
}

export default withAuthenticator(App);
