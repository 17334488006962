import React from 'react';

import { AmplifySignOut } from '@aws-amplify/ui-react';

function NotAdmin({ userObj }) {

  return (
    <div>
      <AmplifySignOut />
      Sorry, it looks like you are not currently an admin in your organization. If you believe this to be a mistake, please contact your IT team.
    </div>
  )
}

export default NotAdmin;