import React from 'react';
import SingleEvent from './singleEvent';

import { getActiveIncidents } from '../../api/api';

function Events({ userObj, }) {
  const [activeEvents, setActiveEvents] = React.useState();

  React.useEffect(() => {
    getActiveIncidents(setActiveEvents, userObj)
  }, [userObj])

  return(
  <div>
    <h2>Active Incidents:</h2>
      {
        activeEvents?.map(aEvent =>
          <SingleEvent key={aEvent.ID} myEvent={aEvent} />
        ) 
      }
  </div>
  )
}

export default Events;