import React from 'react';
import { postBuilding } from '../../api/api';
import AddBuildingImages from './addBuildingImages'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
`

const Form = styled.form`
  display: flex;
  flex-direction: row;
  align-content: space-between;
  justify-content: center;
`

function AddBuildings({ userObj, nextStep, newBuildingID, setNewBuildingID }) {

  
  const [addBuildingComplete, setAddBuildingComplete] = React.useState(false);
  

  function handleSubmit(event) {
    event.preventDefault();
    postBuilding({
      OrgID: userObj.Organization_ID,
      Name: event.target.elements.name.value,
      Description: event.target.elements.description.value
    }, setAddBuildingComplete, setNewBuildingID, nextStep);
  }

  return (
    <Container>
      {
        !addBuildingComplete ? 
        <div>
          <Form onSubmit={handleSubmit}>
            <label htmlFor='name'>Name: </label>
            <input id='name'/>
            <label htmlFor='description'>Description: </label>
            <input id='description'/>
            <button>Submit</button>
          </Form>
        </div>
        : <AddBuildingImages userObj={userObj} buildingID={newBuildingID} nextStep={nextStep} />
      }
    </Container>
  )
}

export default AddBuildings;