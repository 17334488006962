import React from 'react';
import RoomImage from '../../TestImages/room_pin-export.png';
import RoomImageNoLog from '../../TestImages/room_pin_no_log.png'
import styled from 'styled-components'

const StyledCanvas = styled.canvas`
  border: 5px solid #9dbce2;
  border-radius: 5px;
`

const MapCanvas = ({ mapImage, rooms, clickable, addRoomsList, setAddRoomsList, existingRooms, roomsAndLogs }) => {
  const canvasRef = React.useRef(null);
  const [ imgHeight, setImgHeight] = React.useState();
  const [ imgWidth, setImgWidth] = React.useState();
  const [roomCount, setRoomCount ] = React.useState(1)
  const [ originalWidth, setOriginalWidth ] = React.useState()
  const [ originalHeight, setOriginalHeight ] = React.useState()

  React.useEffect(() => {
    function scaleFromScaledMap(value) {
      return imgWidth * (value / originalWidth)
    }

    const context = canvasRef.current.getContext("2d");
    let icon;
    let iconNoLog;
    let img = new Image();
    img.src = mapImage;

    if(rooms || roomsAndLogs || existingRooms) {
      icon = new Image();
      icon.src = RoomImage;
      iconNoLog = new Image();
      iconNoLog.src = RoomImageNoLog;
    } else if(addRoomsList) {
      icon = new Image();
      icon.src = RoomImage;
    }
    
    img.onload = () => {
      if(!imgHeight || !imgWidth) {
        let newWidth = window.outerWidth / 2 - 20
        let ratio = newWidth / img.width
        setImgHeight(ratio * img.height)
        setImgWidth(newWidth)
        setOriginalHeight(img.height)
        setOriginalWidth(img.width)
      }
      context.drawImage(img, 0, 0, imgWidth, imgHeight)
      if(roomsAndLogs) {
        roomsAndLogs.forEach(room => {
          context.drawImage(room.User_ID ? icon : iconNoLog, scaleFromScaledMap(room.Map_Location_X), scaleFromScaledMap(room.Map_Location_Y), 15, 15)
        })
      } else if(addRoomsList) {
        addRoomsList.forEach(room => {
          context.drawImage(icon, room.Scaled_Map_Location_X, room.Scaled_Map_Location_Y, 15, 15)
        })
      }
      if(existingRooms) {
        existingRooms.forEach(room => {
          context.drawImage(icon, scaleFromScaledMap(room.Map_Location_X), scaleFromScaledMap(room.Map_Location_Y), 15, 15)
        })
      }
    }

  }, [canvasRef, mapImage, rooms, addRoomsList, existingRooms, roomsAndLogs, imgHeight, imgWidth, originalWidth, originalHeight])

  function scaleFromOriginalMap(value) {
    return (originalWidth * (value/ imgWidth))
  }

  function handleCanvasOnClick(e) {
    let tempRoom = {
      Scaled_Map_Location_X: Math.round(e.nativeEvent.offsetX) - 7,
      Scaled_Map_Location_Y: Math.round(e.nativeEvent.offsetY) - 7,
      Map_Location_X: Math.round(scaleFromOriginalMap(e.nativeEvent.offsetX)) - 7,
      Map_Location_Y: Math.round(scaleFromOriginalMap(e.nativeEvent.offsetY)) - 7,
      Name: '',
      Description: '',
      Max_Capacity: 0,
      ID: roomCount
    }
    setAddRoomsList([...addRoomsList, tempRoom])
    setRoomCount(roomCount + 1)
  }

  return (
    <StyledCanvas ref={canvasRef} width={imgWidth ? imgWidth : window.outerWidth / 2 - 20} height={imgHeight ? imgHeight : 500} style={{float: 'left'}} onClick={clickable ? handleCanvasOnClick : null} />
  )
}

export default MapCanvas;