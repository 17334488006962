import React from 'react';
import { getOrgs } from '../../api/api';
import AddOrgWorkflow from './addOrg';
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-conent: space-around;
`

const OrgBox = styled.li`
  display: flex;
  border-radius: 5px;
  border: 2px solid #ec2c38;
  background-color: white;
  margin-bottom: 10px;
  padding: 5px;
`

const OrgList = styled.ul`
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  width: 50%;
  margin-left: 20%;
`

const Button = styled.button`
  border-radius: 5px;
  border: 2px solid #ec2c38;
  background-color: white;
  margin-bottom: 10px;
  padding: 5px;
  width: 100px;
  margin-left: 45%;
`

function Organizations({ userObj, }) {

  const [orgs, setOrgs] = React.useState();
  const [addOrg, setAddOrg] = React.useState(false);

  React.useEffect(() => {
    getOrgs(setOrgs)
  }, [])

  function displayNewOrg(newOrg){
    newOrg.ID = orgs.length + 1;
    setOrgs([...orgs, newOrg])
  }

  return(
    <>
      {
        userObj?.SP_User ? 
        <Container>
          <h2>Organizations:</h2>
          <OrgList>
            {
              orgs?.map(org => 
                <OrgBox key={org.ID}>{org.Name} - {org.Description}</OrgBox>
              )
            }
          </OrgList>
          {
            addOrg ? 
            <AddOrgWorkflow cancelButton={()=>setAddOrg(false)} displayNewOrg={displayNewOrg} submitButton={()=>setAddOrg(!addOrg)}/>
            :
            <Button onClick={()=>setAddOrg(!addOrg)}>Add New Org</Button>
          }
          
        </Container>
      : 
      <NonValid />
      }
    </>
  )
}

export default Organizations;

function NonValid() {

  return(
    <div>
      <p>Unfortunately, you don't have access to view this page. If you believe this to be an error, please contact your IT team.</p>
    </div>
  )
}