import React from 'react';
import { getUsersByOrgID } from '../../api/api';
import AddUser from './addUser'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
`

const UserBox = styled.li`
  display: flex;
  border-radius: 5px;
  border: 2px solid #ec2c38;
  background-color: white;
  margin-bottom: 10px;
  padding: 5px;
`

const UserList = styled.ul`
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  width: 50%;
  margin-left: 20%;
`

const Button = styled.button`
  border-radius: 5px;
  border: 2px solid #ec2c38;
  background-color: white;
  margin-bottom: 10px;
  padding: 5px;
  width: 100px;
  margin-left: 45%;
`


function Users({ userObj, }) {

  const [users, setUsers] = React.useState();
  const [addUserBool, setAddUserBool] = React.useState(false);

  React.useEffect(() => {
    getUsersByOrgID(setUsers, userObj);
  }, [userObj])

  function handleAddUserButton() {
    setAddUserBool(!addUserBool)
  }

  function showAddedUser(newUser) {
    handleAddUserButton()
    setUsers([...users, newUser])
  }

  return(
    <Container>
      <h2>Users:</h2>
      <UserList>
        {
          users?.map(user => 
            <UserBox key={user.ID}>{user.Name_Last}, {user.Name_First}</UserBox>
          )
        }
      </UserList>
      {
        addUserBool ? 
          <>
            <AddUser userObj={userObj} showAddedUser={showAddedUser}/>
            <Button onClick={handleAddUserButton}>Cancel</Button>
          </>
        :
          <Button onClick={handleAddUserButton}>Add User</Button>
      }
    </Container>
  )
}

export default Users;