import React from 'react';
import { Link, } from "react-router-dom";
import { Auth } from 'aws-amplify'
import { getOrgs } from '../api/api'
import styled from 'styled-components'

const NavBarContainer = styled.ul`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #9dbce2;
  margin: 0px;
  height: 50px;
`

const NavBarItem = styled.li`
  display: flex;
  color: black;
  :hover {
    background-color: white;
  }
`

const NavBarSpace = styled.li`
  display: flex;
  color: black;
  margin: 5px;
`

const StyledLink = styled(Link)`
  color: black;
  text-decoration: none;
  padding: 16px 5px 16px 5px;
  :hover {
    color: black;
  }
`

const SignOutButton = styled.button`
  border: none;
  background-color: #9dbce2;
  padding: 16px 5px 16px 5px;
  font-size: 1em;
  :hover {
    background-color: white;
  }
`

const NavBarTitle = styled.p`
  font-size: 1.25em;
  margin: 10px;
`

function NavBar({ userObj, }) {

  const [ orgs, setOrgs ] = React.useState([])
  const [ orgName, setOrgName ] = React.useState(userObj.Organization_Name)
  const [ orgID, setOrgID ] = React.useState(userObj.Organization_ID)

  React.useEffect (() => {
    getOrgs(setOrgs)    
  }, [])

  function setNewOrgName(id) {
    orgs.forEach(org => {
      if(org.ID === id) {
        return org.Name
      }    
    })
  }

  async function signOut() {
    try {
        await Auth.signOut();
        window.location.reload()
    } catch (error) {
        console.log('error signing out: ', error);
    }
  }

  return(
    <NavBarContainer>
      <NavBarTitle>SafetyPin</NavBarTitle>
      <NavBarItem>
        <StyledLink to='/'>Home</StyledLink>
      </NavBarItem>
      {
        userObj.SP_User ? 
        <NavBarItem>
          <StyledLink to='/orgs'>Organizations</StyledLink>
        </NavBarItem> 
        : null
      }
      <NavBarItem>
        <StyledLink to='/buildings'>Buildings</StyledLink>
      </NavBarItem>
      <NavBarItem>
        <StyledLink to='/users'>Users</StyledLink>
      </NavBarItem>
      <NavBarItem>
        <StyledLink to='/events'>Incidents</StyledLink>
      </NavBarItem>
      {/* <li id='menuItem'><Link to='/settings'>Settings</Link></li> */}
      {/* <li id='menuItem'>{userObj.Name_First} {userObj.Name_Last}</li> */}
      <NavBarSpace>{orgName}
      {
          userObj.SP_User ?
            <select onChange={e => {
              userObj.Organization_ID = e.target.value
              userObj.Organization_Name = setNewOrgName(e.target.value)
              setOrgID(e.target.value)
              setOrgName(setNewOrgName(e.target.value))
            }}>
            {
              orgs?.map(org =>
                <option key={org.ID} value={org.ID}>{org.Name}</option>  
              )
            }  
            </select>
          : {orgName}
      }
       ID: {orgID}</NavBarSpace>
      <SignOutButton onClick={signOut}>Sign Out</SignOutButton>
    </NavBarContainer>
  )
}

export default NavBar;