import React from 'react';
import DisplayRoomSingle from './displayRoomSingle';

function DisplayRooms({addRoomsList, deleteRoom, updateRoom, adding, setAddRooms, existingRooms, deleteExistingRoom, setExistingRooms}) {

    return(
        <table>
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Description</th>
                    <th>Max Capacity</th>
                    <th>X Pos</th>
                    <th>Y Pos</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
            {
                addRoomsList?.map(room =>
                    <tr key={room.ID}>
                        <DisplayRoomSingle room={room} deleteRoom={deleteRoom} updateRoom={updateRoom} adding={adding} isExisting={false}/>
                    </tr>    
                )
            }
            {
                existingRooms?.map(room =>
                    <tr key={room.ID}>
                        <DisplayRoomSingle room={room} deleteRoom={deleteExistingRoom} updateRoom={setExistingRooms} isExisting={true}/>
                    </tr>    
                )
            }
            {
                !adding ? <tr><td><button onClick={setAddRooms}>Add Rooms</button></td></tr> : null
            }
            </tbody>
        </table>
    )
}

export default DisplayRooms;