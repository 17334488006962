import React from 'react'
import { formatTime } from '../../Components/Modules/time'

function DisplayAllLogs({roomsAndLogs}) {

  const [ sortBy, setSortBy ] = React.useState('Reported-Down');

  function sortRows() {
    if(sortBy === 'Reported-Up') {
      return roomsAndLogs.sort((a, b) => a.User_ID - b.User_ID)
    } else if(sortBy === 'Reported-Down') {
      return roomsAndLogs.sort((a, b) => b.User_ID - a.User_ID)
    } else if(sortBy === 'Time-Up') {
      return roomsAndLogs.sort((a, b) => new Date(a.Created_On).getTime() - new Date(b.Created_On).getTime())
    } else if(sortBy === 'Time-Down') {
      return roomsAndLogs.sort((a, b) => new Date(b.Created_On).getTime() - new Date(a.Created_On).getTime())
    } else if(sortBy === 'Room-Up') {
      return roomsAndLogs.sort((a, b) => a.Room_Name > b.Room_Name ? -1 : 1)
    } else if(sortBy === 'Room-Down') {
      return roomsAndLogs.sort((a, b) => b.Room_Name > a.Room_Name ? -1 : 1)
    } else if(sortBy === 'Teacher-Up') {
      return roomsAndLogs.sort((a, b) => a.User_Name_Last > b.User_Name_Last ? -1 : 1)
    } else if(sortBy === 'Teacher-Down') {
      return roomsAndLogs.sort((a, b) => b.User_Name_Last > a.User_Name_Last ? -1 : 1)
    }
  }

  function handleReportedSortBy() {
    setSortBy(sortBy === 'Reported-Up' ? 'Reported-Down' : 'Reported-Up')
  }

  function handleTimeSortBy() {
    setSortBy(sortBy === 'Time-Up' ? 'Time-Down' : 'Time-Up')
  }

  function handleRoomSortBy() {
    setSortBy(sortBy === 'Room-Up' ? 'Room-Down' : 'Room-Up')
  }

  function handleTeacherSortBy() {
    setSortBy(sortBy === 'Teacher-Up' ? 'Teacher-Down' : 'Teacher-Up')
  }

  return(
    <table>
      <thead>
        <tr>
          <td onClick={handleReportedSortBy}>Reported</td>
          <td onClick={handleTimeSortBy}>Reported Time</td>
          <td onClick={handleRoomSortBy}>Room</td>
          <td onClick={handleTeacherSortBy}>Teacher</td>
          <td>Student Count</td>
          <td>Staff Count</td>
          <td>Note</td>
        </tr>
      </thead>
      <tbody>
        {
          sortRows().map(log =>
            <tr key={log.Room_ID}>
              <td>{log.User_ID ? 'Yes' : 'No'}</td>
              <td>{log.Created_On ? formatTime(log.Created_On, 'h:m.sq') : '-'}</td>
              <td>{log.Room_Name}</td>
              <td>{log.User_Name_First ? `${log.User_Name_First} ${log.User_Name_Last}` : 'Unassigned'}</td>
              <td>{log.Head_Count ? log.Head_Count : '-'}/{log.Max_Capacity}</td>
              <td>{log.Staff_Count ? log.Staff_Count : '-'}</td>
              <td>{log.Notes ? log.Notes : '-'}</td>
            </tr>
          )
        }
      </tbody>
    </table>
  )
}

export default DisplayAllLogs