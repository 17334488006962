import React from 'react';
import { postBuildingImage } from '../../api/api';
import { Storage } from 'aws-amplify'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
`

const ImageDropBox = styled.input`
    display: flex;
    align-content: center;
    border-radius: 5px;
    border: 2px solid #ec2c38;
    background-color: white;
    margin-bottom: 10px;
    padding: 5px;
    width: 200px;
    margin-left: 44%;
`

    function S3ImageUpload({userObj, buildingID, setIsUploading, isEditing, location, setUpdatedImage}) {

        function onChange(e) {
            const file = e.target.files[0];
            let imageNameAndLocation = !isEditing ? `buildingimages/${userObj.Organization_ID}/${buildingID}/${file.lastModified}.${file.type.slice(-3)}` : location
            Storage.put(imageNameAndLocation, file, {
                contentType: file.type
            })
            .then (() => {
                setIsUploading(true)
                if(!isEditing) {
                    postBuildingImage(buildingID, imageNameAndLocation)
                } else {
                    setUpdatedImage(true)
                }
            })
            .catch(err => console.log(err));
        }

        return (
            <Container>
                <ImageDropBox
                    type="file" accept='image/png, .jpeg, .jpg'
                    onChange={(evt) => onChange(evt)}
                />
            </Container>
        )

}

export default S3ImageUpload;