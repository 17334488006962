import React from 'react'
import styled from 'styled-components'

const FooterBar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #9dbce2;
  margin-top: 20px;
  height: 150px;
`

function Footer() {

  return(
    <FooterBar>
      
    </FooterBar>
  )
}

export default Footer;