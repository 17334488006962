import React from 'react';
import { Auth } from 'aws-amplify';
import { getUserByExternalID } from '../api/api';

function UserValidator({Valid, NotValid}) {
  const [userIDExt, setUserIDExt] = React.useState();
  const [userObj, setUserObj] = React.useState();

  React.useEffect(() => {
    Auth.currentAuthenticatedUser()
    .then(myUser => setUserIDExt(myUser.attributes.sub))
  }, []);

  React.useEffect( ()=> {
    if(userIDExt) {
      getUserByExternalID(userIDExt, setUserObj)
    }
  }, [userIDExt]);
  

  return (
    <div>
    {
      userObj?.Admin ? <Valid userObj={userObj}/> : <NotValid userObj={userObj}/>
    }
    </div>
  )
}

export default UserValidator;