import React from 'react';
import { postOrg } from '../../api/api'
import styled from 'styled-components'

const Container = styled.div`
  border-radius: 5px;
  border: 2px solid #ec2c38;
  background-color: white;
  margin-bottom: 10px;
  padding: 5px;
  width: 50%;
  margin-left: 25%;
`

const Button = styled.button`
  border-radius: 5px;
  border: 2px solid #ec2c38;
  background-color: white;
  margin-bottom: 10px;
  padding: 5px;
  width: 100px;
`

function AddOrgWorkflow({ cancelButton, displayNewOrg, submitButton}) {

  function handleSubmit(event) {
    event.preventDefault();
    postOrg({
      orgName: event.target.elements.newOrgName.value,
      orgDesc: event.target.elements.newOrgDesc.value
    }, displayNewOrg)
    submitButton()
  }

  return (
    <Container>
      <form onSubmit={handleSubmit}>
        <label htmlFor='newOrgName'>New Organization Name: </label>
        <input id='newOrgName'/>
        <label htmlFor='newOrgDesc'>New Organization Description: </label>
        <input id='newOrgDesc'/>
        <Button type='submit'>Submit</Button>
      </form>
      <Button onClick={cancelButton}>Cancel</Button>
    </Container>
  )
}

export default AddOrgWorkflow;