import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import MapCanvas from '../../Components/Canvas/MapCanvas';
import DisplayRooms from './rooms/displayRooms';
import { getBuildingByID, getRoomsByBuildingID, getBuildingImageByBuildingID, deleteRoomByID, updateRoomByID, updateBuildingByID, deleteBuildingByID, deleteAllRoomsByBuildingID } from '../../api/api';
import AddBuildingImages from './addBuildingImages';
import AddRooms from './rooms/addRooms'

function ShowBuilding({ userObj, }) {

    const {id} = useParams();
    const [ myBuilding, setMyBuilding] = React.useState();
    const [ buildingImage, setBuildingImage ] = React.useState();
    const [ myRooms, setMyRooms ] = React.useState();
    const [ isEditing, setIsEditing ] = React.useState();
    const [ updatedImage, setUpdatedImage ] = React.useState(false);
    const [ addRooms, setAddRooms ] = React.useState(false);
    const history = useHistory()

    React.useEffect(() => {
        getBuildingByID(id, setMyBuilding);
        getRoomsByBuildingID(id, setMyRooms);
        getBuildingImageByBuildingID(setBuildingImage, id);
    }, [id, updatedImage])

    function updateRoom(data) {
        updateRoomByID(data)
        let templist = [];
        myRooms.forEach(room => {
            if(room.ID === data.ID){
                templist.push(data)
            } else {
                templist.push(room)
            }
        })
        setMyRooms(templist);
    }

    function deleteRoom(id) {
        deleteRoomByID(id)
        let templist = [];
        myRooms.forEach(room => {
            if(room.ID !== id){
                templist.push(room)
            }
        })
        setMyRooms(templist);
    }

    function handleSubmit(e) {
        e.preventDefault();
        let data = {
            ID: myBuilding.ID,
            Name: e.target.elements.buildingName.value,
            Description: e.target.elements.description.value,
            Organization_ID: myBuilding.Organization_ID
        }
        setMyBuilding({...data})
        updateBuildingByID(data, () => setIsEditing(!isEditing))
        if(updatedImage){
            deleteAllRoomsByBuildingID(myBuilding.ID)
            setMyRooms([])
        }
    }

    function deleteBuilding() {
        deleteBuildingByID(myBuilding.ID, () => history.replace('/buildings'))
    }

    function displayNewRooms(newRooms) {
        setMyRooms([...myRooms, ...newRooms])

    }
 
    return (
        <>
            {
                myBuilding ? 
                <div>
                    {
                        !addRooms ? 
                            <>
                                <button onClick={() => setIsEditing(!isEditing)}>Edit</button> 
                                {
                                    userObj.SP_User ? <button onClick={deleteBuilding}>Delete</button> : null
                                }
                            </>
                        : null
                    }
                    
                    {
                        isEditing ? 
                        <>
                            <form onSubmit={handleSubmit}>
                                <label htmlFor='buildingName'>Building Name:<input id='buildingName' defaultValue={myBuilding.Name}/></label>
                                <label htmlFor='description'>Description: <input id='description' defaultValue={myBuilding.Description}/></label>
                                <button type='submit'>Submit</button>
                            </form>
                            <AddBuildingImages userObj={userObj} buildingID={myBuilding.ID} isEditing={isEditing} location={myBuilding.Image_Location} setUpdatedImage={setUpdatedImage} />
                        </>
                        :
                        <>
                            <h2>{myBuilding.Name}</h2>
                            {
                                addRooms ? 
                                    <>
                                        <AddRooms buildingID={myBuilding.ID} existingRooms={myRooms} setExistingRooms={updateRoom} deleteExistingRoom={deleteRoom} setAddRooms={setAddRooms} displayNewRooms={displayNewRooms}/>
                                    </> : 
                                    <>
                                        <MapCanvas mapImage={buildingImage} existingRooms={myRooms} /> 
                                        <DisplayRooms addRoomsList={myRooms} deleteRoom={deleteRoom} updateRoom={updateRoom} setAddRooms={() => setAddRooms(!addRooms)}/>   
                                    </>
                            }
                        </>
                    }
                    
                </div>
                : <p>Is loading...</p>
            }
        </>
    )
}

export default ShowBuilding;