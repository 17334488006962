import React from 'react';
import { getBuildingsByOrgID } from '../../api/api';
import AddBuildingWorkflow from './addBuildingWorkflow';
import { Link, } from "react-router-dom"
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-conent: space-around;
`

const BuildingBox = styled.li`
  display: flex;
  border-radius: 5px;
  border: 2px solid #ec2c38;
  background-color: white;
  margin-bottom: 10px;
  padding: 5px;
`

const BuildingList = styled.ul`
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  width: 50%;
  margin-left: 20%;
`

const Button = styled.button`
  border-radius: 5px;
  border: 2px solid #ec2c38;
  background-color: white;
  margin-bottom: 10px;
  padding: 5px;
  width: 100px;
  margin-left: 45%;
`

function Building({ userObj, }) {
  const [buildings, setBuildings] = React.useState();
  const [addBuildings, setAddBuildings] = React.useState(false);

  React.useEffect(() => {
    getBuildingsByOrgID(setBuildings, userObj);
  }, [userObj])

  function handleAddBuildings() {
    setAddBuildings(!addBuildings)
  }

  return(
    <Container>
      
      {
        addBuildings ?
        <div>
          <AddBuildingWorkflow userObj={userObj} />
        </div>
        : 
        <div>
        <h2>Buildings:</h2>
        <BuildingList>
          {
            buildings?.map(building => 
              <Link key={building.ID} to={`/building/${building.ID}`}><BuildingBox key={building.ID}>{building.Name}</BuildingBox></Link>
              )
            }
        </BuildingList>
        </div>
      }
      <Button onClick={handleAddBuildings}>{!addBuildings ? 'Add Buildings' : 'Cancel'}</Button>
    </Container>
  )
}

export default Building;