import React from 'react';
import S3ImageUpload from '../../Components/Common/imageUpload';

  function AddBuildingImages( {userObj, buildingID, nextStep, isEditing, location, setUpdatedImage}) {

  
  const [isUploading, setIsUploading] = React.useState(false);

  function handleSubmit() {
    nextStep();
  }

  return( 
    <div>
      Upload Images from here.
      <S3ImageUpload userObj={userObj} buildingID={buildingID} setIsUploading={setIsUploading} isEditing={isEditing} location={location} setUpdatedImage={setUpdatedImage}/>
      {
        !isEditing ? <button onClick={handleSubmit} disabled={!isUploading}>Submit</button> : null
      }
    </div>
  )
}

export default AddBuildingImages;