import React from 'react';
import AddBuildings from './addBuildings';
import AddRooms from './rooms/addRooms';
import { useHistory } from 'react-router-dom'

function AddBuildingWorkflow({ userObj }) {

    const [currentStep, setCurrentStep] = React.useState(0);
    const [newBuildingID, setNewBuildingID] = React.useState();
    const history = useHistory();

    function redirectToBuilding(id) {
        history.replace(`/building/${id}`)
    }

    function displayCurrentStep() {
        if(currentStep === 0 || currentStep === 1) {
            return <AddBuildings userObj={userObj} nextStep={() => setCurrentStep(currentStep + 1)} newBuildingID={newBuildingID} setNewBuildingID={setNewBuildingID} />
        }
        if(currentStep === 2) {
            return <AddRooms buildingID={newBuildingID} nextStep={redirectToBuilding}/>
        }
    }

    return (
        <div>
            <h2>Add Building</h2>
            {
                displayCurrentStep()
            }
        </div>
    )
}

export default AddBuildingWorkflow;