import React from 'react'
import DisplayAllLogs from './displayAllLogs'
import MapCanvas from '../../Components/Canvas/MapCanvas';
import { getBuildingImage, getRoomsAndIncidentReportsByBuildingID } from '../../api/api'
import styled from 'styled-components'

const EventContainer = styled.div`
  display: flex;
`

const LogColumn = styled.div`
  display: flex;
  flex-direction: column;
`

function SingleEvent( {myEvent }) {

  const [myMap, setMyMap] = React.useState();
  const [ roomsAndLogs, setRoomsAndLogs ] = React.useState([])

  React.useEffect(() => {
    getRoomsAndIncidentReportsByBuildingID(setRoomsAndLogs, myEvent.Building_ID)
  }, [myEvent.Building_ID])

  React.useEffect(() => {
    getBuildingImage(setMyMap, myEvent)
  }, [myEvent] ) 

  return (
    <div>
      <h2>Incident: {myEvent.Title}</h2>
      <EventContainer>
        {
          myMap ? 
            <LogColumn>
              <MapCanvas mapImage={myMap} roomsAndLogs={roomsAndLogs}/>
            </LogColumn>
          : <p>Is Loading...</p>
        }
        <LogColumn>
          <DisplayAllLogs roomsAndLogs={roomsAndLogs}/>
        </LogColumn>
      </EventContainer>
    </div>
  )
}

export default SingleEvent;