/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:b70e778c-d8a9-4270-b5bb-2fc11e0f78b9",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_pHRJSUuxT",
    "aws_user_pools_web_client_id": "5lcberm2osho6e3bclj7ffh9sv",
    "oauth": {},
    "aws_user_files_s3_bucket": "sp-map-images135658-dev",
    "aws_user_files_s3_bucket_region": "us-west-2"
};


export default awsmobile;
