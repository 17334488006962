import axios from 'axios';
import { Storage, Auth } from 'aws-amplify'
import { config } from '../Constants'

const URL = config.url.API_URL

function getData (setter, url, debug = false) {
  axios({
    method: 'GET',
    url
  })
  .then(myData => {
    if(debug) {
      console.log(myData)
    }
    if(myData.data.data.length > 1){
      setter([...myData.data.data])
    } else {
      setter(myData.data.data)
    }
  })
  .catch(err => {
    console.log(err)
  })
}

export function getActiveIncidents(setter, userObj) {
    getData(setter, URL + `incident/get/incidents/active/orgID=${userObj.Organization_ID}`);
}

export function getBuildingImage(setter, myEvent) {
    axios({
        method: 'GET',
        url: URL + `get/building/images/bldgID=${myEvent.Building_ID}`
      })
      .then(myData => {
        Storage.get(myData.data.data[0]?.Image_Location)
        .then(getPromise => setter(getPromise))
        .catch(err => console.log(err))
      })
      .catch(err => {
        console.log(err)
      })
}

export function getBuildingImageByBuildingID(setter, buildingID, setImageHeight = null) {
  axios({
      method: 'GET',
      url: URL + `get/building/images/bldgID=${buildingID}`
    })
    .then(myData => {
      Storage.get(myData.data.data[0]?.Image_Location)
      .then(getPromise => {
        setter(getPromise)
        if(setImageHeight) {
          let img = new Image();
          img.src = getPromise;
          img.onload = () => {
              setImageHeight(`${img.height / 2}px`)
          }
        }
      })
      .catch(err => console.log(err))
    })
    .catch(err => {
      console.log(err)
    })
}

export function getIncidentReports(setter, myEvent) {
  getData(setter, URL + `incident/get/incidentreport/incidentID=${myEvent.ID}`)
}

export function postBuildingImage(buildingID, fileName) {
  axios({
    method: 'POST',
    url: URL + `post/building/image/`,
    data: {
      Building_ID: buildingID,
      Image_Location: fileName,
    }
  })
  .then(
    myData => {
      //handleAddBuildings()
    })
  .catch(err => {
    console.log(err)
  })
}

export function postBuilding(data, setAddBuildingComplete, setNewBuildingID, nextStep) {
  axios({
    method: 'POST',
    url: URL + 'post/building',
    data
  })
  .then(myData => {
    setAddBuildingComplete(true)
    setNewBuildingID(myData.data.data[0].currval)
    nextStep()
  })
  .catch(err => {
    console.log(err)
  })
}

export function getBuildingsByOrgID(setter, userObj) {
  getData(setter, URL + `get/buildings/OrgID=${userObj.Organization_ID}`)
}

export function getOrgs(setter) {
  getData(setter, URL + `get/orgs`)
}

export function postOrg(data, displayNewOrg) {
  axios({
    method: 'POST',
    url: URL + `post/org`,
    data
  })
  .then(() => {
    displayNewOrg({
      Name: data.orgName,
      Description: data.orgDesc
    })
  })
  .catch(err => {
    console.log(err)
  })
}

export function getUsersByOrgID (setter, userObj ){ 
  getData(setter, URL + `user/get/users/OrgID=${userObj.Organization_ID}`)
}

export function getRoomsAndUsersByBuilding( setRooms, buildingID) {
  axios({
    method: 'GET',
    url: URL + `get/rooms/users/bldgID=${buildingID}`
  })
  .then(myData => {
    setRooms([...myData.data.data.rooms])
  })
  .catch(err => {
    console.log(err)
  })
}

export function postRoomByBuildingID(roomsList, buildingID, redirectToBuilding){
  roomsList.forEach((room, i) => {
    axios({
      method: 'POST',
      url: URL + `post/rooms/bldgID=${buildingID}`,
      data: room
    })
    .then(() => {
      if(i + 1 === roomsList.length){
        redirectToBuilding(buildingID)
      }
    })
    .catch(err => {
      console.log(err)
    })
  })
}

export function getBuildingByID(id, setter) {
  getData(setter, URL + `get/building/ID=${id}`)
}

export function getRoomsByBuildingID(id, setter) {
  getData(setter, URL + `get/rooms/bldgID=${id}`)
}

export function deleteRoomByID(id) {
  axios({
    method: 'DELETE',
    url: URL + `delete/room/roomID=${id}`
  })
  .then(res => {
    console.log(res)
  })
  .catch(err => {
    console.log(err)
  })
}

export function updateRoomByID(data) {
  axios({
    method: 'PUT',
    url: URL + `update/room/roomID=${data.ID}`,
    data
  })
  .then(res => {
    //console.log(res)
  })
  .catch(err => {
    console.log(err)
  })
}

export function updateBuildingByID(data, closeEdit) {
  axios({
    method: 'PUT',
    url: URL + `update/building/bldgID=${data.ID}`,
    data
  })
  .then(res => {
    closeEdit()
  })
  .catch(err => {
    console.log(err)
  })
}

export function deleteBuildingByID(id, redirect) {
  axios({
    method: 'DELETE',
    url: URL + `delete/building/bldgID=${id}`
  })
  .then(res => {
    //console.log(res)
    redirect()
  })
  .catch(err => {
    console.log(err)
  })
}

export function deleteAllRoomsByBuildingID(id) {
  axios({
    method: 'DELETE',
    url: URL + `delete/rooms/bldgID=${id}`
  })
  .then(res => {
    //console.log(res)
  })
  .catch(err => {
    console.log(err)
  })
}

export function getUserByExternalID(id, setter) {
  axios({
    url: URL + `user/get/user/ExtUserID='${id.toString()}'`,
    method: 'GET'
  })
  .then(myData => {
    setter(myData?.data.data[0])
  })
}

export function submitNewUser(awsData, pgData, showAddedUser) {
  Auth.signUp(awsData)
  .then(data => {
    pgData.extUserID = data.userSub;
    axios({
      method: 'POST',
      url: URL + `user/post/user/admin/finish`,
      data: pgData
    })
    .then(() => {
      pgData.Name_First = pgData.nameFirst
      pgData.Name_Last = pgData.nameLast
      pgData.ID = 9999 //this is dumb but works for now
      showAddedUser(pgData)
    })
    .catch(err => {
      console.log(err)
    })
  })
  .catch(err => console.log(err))
}

export function getRoomsAndIncidentReportsByBuildingID(setter, buildingID) {
  getData(setter, `${URL}get/rooms/incidentreports/bldgID=${buildingID}`)
}