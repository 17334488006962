import React from 'react'

function StatefulSelect({ myList, setter}) {

    return(
        <select onChange={setter}>
        {
            myList?.map(item =>
                <option key={item.ID} value={item.ID}>{item.Name}</option>    
            )
        }
        </select>
    )
}

export default StatefulSelect;