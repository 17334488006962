import React from 'react';

function DisplayRoomSingle({ room, deleteRoom, updateRoom, adding, isExisting }) {

    const [ roomName, setRoomName ] = React.useState(room.Name);
    const [ roomDesc, setRoomDesc ] = React.useState(room.Description);
    const [ roomCap, setRoomCap ] = React.useState(room.Max_Capacity);
    const [ roomX, setRoomX ] = React.useState(room.Map_Location_X);
    const [ roomY, setRoomY ] = React.useState(room.Map_Location_Y);
    const [ editing, setEditing ] = React.useState(adding);

    function handleChange(e, setter) {
        let mostRecent = {key: e.target.id, value: e.target.value}
        setter()
        if(!isExisting) {
            updateRoom(returnRoom(mostRecent))
        }
    }

    function handleUpdateButton() {
        updateRoom(returnRoom())
        if(!adding){
            setEditing(false)
        }
    }

    function returnRoom (mostRecent = null) {
        let data = {
            ID: room.ID,
            Name: roomName,
            Description: roomDesc,
            Max_Capacity: roomCap,
            Map_Location_X: roomX,
            Map_Location_Y: roomY,
            Scaled_Map_Location_X: room.Scaled_Map_Location_X,
            Scaled_Map_Location_Y: room.Scaled_Map_Location_Y
        }
        if(mostRecent) {
            data[mostRecent.key] = mostRecent.value
        }
        return data
    }

    return(
        editing ?
        <>
            <td><input id='Name' value={roomName} onChange={e => handleChange(e, () => setRoomName(e.target.value))}/></td>
            <td><input id='Description' value={roomDesc} onChange={e => handleChange(e, () => setRoomDesc(e.target.value))}/></td>
            <td><input id='Max_Capacity' type='number' value={roomCap} onChange={e => handleChange(e, () => setRoomCap(e.target.value))} /></td>
            <td><input id='Map_Location_X' type='number' value={roomX} onChange={e => handleChange(e, () => setRoomX(e.target.value))}/></td>
            <td><input id='Map_Location_Y' type='number' value={roomY} onChange={e => handleChange(e, () => setRoomY(e.target.value))}/></td>
            <td><button onClick={handleUpdateButton}>Update</button><button onClick={() => deleteRoom(room.ID)}>Delete</button></td>
        </> 
        :
        <>
            <td>{roomName}</td>
            <td>{roomDesc}</td>
            <td>{roomCap}</td>
            <td>{roomX}</td>
            <td>{roomY}</td>
            <td><button onClick={() => setEditing(true)}>Edit</button><button onClick={() => deleteRoom(room.ID)}>Delete</button></td>
        </> 
    )
}

export default DisplayRoomSingle;